<template>
  <div class="password-input">
    <text-input
      :id="id"
      :input-type="toggledInputType"
      :placeholder="placeholder"
      :value="value"
      autocapitalize="none"
      class="password-input__input"
      data-test-id="password-input"
      @input="(value) => $emit('input', value)"
    >
      <slot>Password</slot>
      <template v-slot:inlineButton>
        <button
          class="password-input__visibility-button"
          type="button"
          @click="toggleVisibility"
        >
          <icon-component
            v-show="!isVisible"
            name="password-show"
            title="show password"
            class="password-input__visibility-button-icon"
          />
          <icon-component
            v-show="isVisible"
            name="password-hide"
            title="hide password"
            class="password-input__visibility-button-icon"
          />
        </button>
      </template>
    </text-input>
    <p
      v-if="showDisclaimer"
      class="input-disclaimer disclaimer-text"
      data-test-id="password-input-disclaimer-text"
    >
      <slot name="disclaimer">
        Your password must contain at least 8 characters and a number
      </slot>
    </p>
  </div>
</template>

<script>
import TextInput from '@/components/Inputs/TextInput.vue';

export default {
  name: 'PasswordInput',
  components: {
    TextInput,
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: 'password',
    },
    showDisclaimer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: false,
      toggledInputType: 'password',
    };
  },
  methods: {
    toggleVisibility() {
      if (this.isVisible) {
        this.isVisible = false;
        this.toggledInputType = 'password';
      } else {
        this.isVisible = true;
        this.toggledInputType = 'text';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_password-input';
  @import '@/stylesheets/components/_input-disclaimer';
</style>

<style lang="scss">
  .password-input__input input::-ms-reveal {
    display: none;
  }
</style>

<docs>
</docs>
