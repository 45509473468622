<template>
  <div class="content-driver-banner">
    <!-- The element below should be a div and not a p tag
      to protect against invalid markup if the content
      contains one.
    -->
    <div
      class="content-driver-banner__text"
      v-html="bannerContent"
    />
  </div>
</template>

<script>
import qualtricsLinkParamsMixin from '@/mixins/qualtrics-link-params-mixin';

export default {
  name: 'ContentDriverBanner',
  mixins: [qualtricsLinkParamsMixin],
  computed: {
    bannerContent() {
      return this.insertQualtricsParams(this.$site.settings.content_driver_banner);
    },
  },
};
</script>

<style lang="scss">
  @import '@/stylesheets/components/_content-driver-banner';
</style>
